import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Loader from "react-js-loader";
import { apiHandler } from '../../api/apihandler';
import { EVENT_ADD_TO_FAVORITE, EVENT_MEMBERS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import CommentList from '../comment/commentList'

// images
import user05 from '../../assets/images/user/05.jpg'
import user06 from '../../assets/images/user/06.jpg'
import user07 from '../../assets/images/user/07.jpg'
import user08 from '../../assets/images/user/08.jpg'
import user09 from '../../assets/images/user/09.jpg'
import likeFillIcon from '../../assets/images/icon/like_fill_icon.png'
import TicketBuyPopup from './ticketBuyPopup'
import EventParticipateModal from './eventParticipateModal'
// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import LoaderModal from '../loaderModal'


const EventCard = ({ elm, getEventList, isPast }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [memberList, setMemberList] = useState([]);
    const [showTicketBuy, setShowTicketBuy] = useState(false);
    const [showParticipateModal, setShowParticipateModal] = useState(false);
    const [isFavorite, setIsFavorite] = useState(elm?.is_favorite);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {

        if (parseInt(localStorage.getItem("uzoneSelectedTicketId")) === parseInt(elm?.id)) {
            buyTicketManage()
            //console.log("eventId",localStorage.getItem("uzoneSelectedTicketId") +" "+elm?.id)
        }

        //getMembersList()
    }, [])

    async function getMembersList() {
        try {
            const res = await apiHandler(EVENT_MEMBERS, "POST", {
                event_id: elm?.id,
                page: 1,
                limit: 6
            })
            if (res.data?.response?.status === 200) {
                setMemberList(res?.data?.data?.eventParticipants?.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    function buyTicketManage() {
        //console.log("test", elm?.id+" "+ localStorage.getItem("uzoneSelectedTicketId"))
        if (!isPast) {
            setShowTicketBuy(true)
            localStorage.setItem('uzoneSelectedTicketId', "")
        }
    }

    async function addRemoveToFavorite() {
        setShowLoader(true)
        try {
            const res = await apiHandler(EVENT_ADD_TO_FAVORITE, "POST", {
                event_id: elm?.id
            })
            if (res.data?.response?.status === 200) {
                setIsFavorite(isFavorite === 0 ? 1 : 0)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }


    return (
        <>
            {/* <div style={{ width: "33%" }}> */}
            <LoaderModal showLoader={showLoader} />
            <div>
                <Card className="rounded  mb-0" >
                    <div className="event-images" style={{ display: "contents", position: "relative" }}>
                        <Link to={`/dashboards/app/event-detail/${elm?.slug}`}>
                            <img src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                className="img-fluid" alt="Responsive"
                                style={{ maxHeight: "250px", width: "100%", objectFit: "cover" }} />
                        </Link>
                        <div style={{
                            background: "white", borderRadius: "50%", width: "25px", height: "25px", marginTop: "8px",
                            marginLeft: "8px", cursor: "pointer", position: "absolute", textAlign: "center"
                        }}
                            onClick={() => addRemoveToFavorite()}>
                            {
                                isFavorite === 0 ?
                                    <i className="ri-heart-line h4"></i> :
                                    <i className="ri-heart-fill h4" style={{ color: "red" }}></i>
                            }
                        </div>
                    </div>
                    <Card.Body>
                        <div>
                            <div className="d-flex w-100">
                                <div className="date-of-event">
                                    <span>{Moment(elm?.created_at).format('MMM')}</span>
                                    <h5>{Moment(elm?.created_at).format('DD')}</h5>
                                </div>
                                <div className="events-detail ms-3">
                                    <h5><Link to={`/dashboards/app/event-detail/${elm?.slug}`}
                                        onClick={() => {
                                            localStorage.setItem('eventIsPast', isPast)
                                        }}>{elm?.name}</Link></h5>
                                    {/* <h5>{"$" + elm?.price}</h5> */}
                                    <p>{elm?.city + ", " + elm?.state + ", " + elm?.zip}</p>
                                    {/* <div className="event-member">
                                        <div className="iq-media-group">
                                            {
                                                memberList?.length > 0 ? memberList.map((memberElm, i) => (
                                                    <Link to="#" className="iq-media" key={memberElm?.id}>
                                                        <img className="img-fluid avatar-40 rounded-circle" src={memberElm?.member?.user_setting?.photo ? memberElm?.member?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                                    </Link>
                                                )) : null}
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="card-post-toolbar">
                                    <h5>{"$" + elm?.price}</h5>
                                </div> */}
                            </div>
                            {
                                isPast ? null :
                                    <div className="d-flex">
                                        {
                                            profileData?.user_type === 2 || profileData?.user_setting?.is_influencer === 1 || profileData?.user_setting?.is_dance_corner === 1 ?
                                                <button type="submit" className="btn btn-primary d-block w-100" style={{ marginRight: "5px" }}
                                                    onClick={() => {
                                                        if (localStorage.getItem("uzoneAuthToken")) {
                                                            setShowParticipateModal(true)
                                                        } else {
                                                            navigate('/auth/sign-in')
                                                        }
                                                    }}
                                                >Add Participant</button> :
                                                <button type="submit" className="btn btn-primary d-block w-100" style={{ marginRight: "5px" }}
                                                    onClick={() => {
                                                        if (localStorage.getItem("uzoneAuthToken")) {
                                                            setShowTicketBuy(true)
                                                        } else {
                                                            navigate('/auth/sign-in')
                                                        }
                                                    }}>Buy a Ticket</button>
                                        }
                                        {
                                            profileData?.user_setting?.is_influencer === 1 || profileData?.user_setting?.is_dance_corner === 1 ?
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="bg-transparent" className="btn btn-primary d-block w-20">
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className=" m-0 p-0">
                                                        <Dropdown.Item className="pl-3 pr-3 pt-3" to="#"
                                                            onClick={() => {
                                                                if (localStorage.getItem("uzoneAuthToken")) {
                                                                    setShowTicketBuy(true)
                                                                } else {
                                                                    navigate('/auth/sign-in')
                                                                }
                                                            }}>
                                                            <div className="d-flex align-items-top">
                                                                <i className="ri-coupon-line h4"></i>
                                                                <div className="data ms-2">
                                                                    <h6>Buy a Ticket</h6>
                                                                    <p className="mb-0">${elm?.ticket_price} per ticket</p>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> : null
                                        }
                                    </div>
                            }
                        </div>
                    </Card.Body>
                </Card>

                <TicketBuyPopup elm={elm} showTicketBuy={showTicketBuy} setShowTicketBuy={setShowTicketBuy} getEventList={getEventList} />
                {
                    showParticipateModal ?
                        <EventParticipateModal
                            showParticipateModal={showParticipateModal}
                            setShowParticipateModal={setShowParticipateModal}
                            eventDetails={elm} /> : null
                }
            </div>

        </>
    )
}

export default EventCard
